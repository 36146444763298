////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(0, 0, 0);
}
.bg-secondaryColor {
  background-color: rgb(132, 191, 73);
}
.bg-thirdColor {
  background-color: rgb(69, 69, 70);
}
.bg-fourthColor {
  background-color: rgb(132, 191, 73);
}
.bg-fifthColor {
  background-color: rgba(132, 191, 73, 0.75);
}
.bg-sixthColor {
  background-color: rgb(234, 230, 230);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
